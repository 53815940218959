/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


$font-family: Inter;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// styles.scss







//snackbar styles

.green-snackbar {
    background-color: green;
    color: white;
  }
  
  .red-snackbar {
    background-color: red;
    color: white;
  }